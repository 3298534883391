<template>
  <v-container fluid class="ma-0 pa-0" style="align-items: center">
    <v-row justify="center" class="ma-0 mt-12 pa-0">
      <h1>
        Destinataire : {{ $route.query.dest || "hebergementjeunes@free.fr" }}
      </h1>
    </v-row>
    <v-row justify="center" class="ma-0 pa-0">
      <v-col xs="10" sm="10" md="8" lg="7" xl="7" class="ma-0 pa-0">
        <v-card width="100%" class="ma-0 mt-8 mb-12 pa-6">
          <v-form>
            <v-row class="ma-0 pa-0">
              <v-text-field
                class="pr-3"
                label="Adresse mail*"
                v-model="email"
              ></v-text-field>
              <v-text-field
                class="pl-3"
                label="Nom Prénom*"
                v-model="name"
              ></v-text-field>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-text-field
                class="pr-3"
                label="Numéro de téléphone*"
                v-model="phone"
              ></v-text-field>
              <v-text-field
                class="pl-3"
                label="Adresse*"
                v-model="address"
              ></v-text-field>
              <v-text-field
                class="pl-3"
                label="code postal*"
                v-model="zipcode"
              ></v-text-field>
              <v-text-field
                class="pl-3"
                label="Ville*"
                v-model="city"
              ></v-text-field>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-text-field
                class="pr-3"
                label="École*"
                v-model="school"
              ></v-text-field>
              <v-text-field
                class="pl-3"
                label="Entreprise"
                v-model="company"
              ></v-text-field>
            </v-row>
            <v-textarea v-model="message" label="Message*"></v-textarea>
            <v-row class="ma-0 pa-0 error--text">
              <p>{{ errorMessage }}</p>
            </v-row>
            <v-btn :loading="sendLoading" @click="submit">envoyer</v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-sheet height="30"></v-sheet>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <v-row class="ma-0 pa-0">
            Informations
          </v-row>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-row class="ma-0 pa-0">
            <v-icon class="mr-4" color="success">mdi-check</v-icon>
            Votre message a bien été envoyé !
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("user_qGzPE5MpTxODEuN0geimX");

export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      address: "",
      zipcode: "",
      city: "",
      school: "",
      company: "",
      message: "",
      sendLoading: false,
      dialog: false,
      errorMessage: "",
    };
  },
  methods: {
    submit() {
      if (this.name != "" && this.phone != "" && this.email != "" && this.address != "" && this.address != "" && this.zipcode != "" && this.city != "" && this.school != "") {
        this.sendLoading = true;
        emailjs
          .send("service_64ztu0t", "template_xnj4k0j", {
            to: this.$route.query.dest || "hebergementjeunes@free.fr",
            name: this.name,
            email: this.email,
            phone: this.phone,
            address: this.address,
            zipcode: this.zipcode,
            city: this.city,
            message: this.message,
            school: this.school,
            company: this.company,
            owner: this.$route.query.owner,
          })
          .then(() => {
            this.name = "",
            this.phone = "",
            this.email = "",
            this.address = "",
            this.zipcode = "",
            this.city = "",
            this.school = "",
            this.company = "",
            this.message = "",
            this.dialog = true;
            this.sendLoading = false;
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue: " + error;
            this.sendLoading = false;
          });
      } else {
        this.errorMessage = "Tous les champs doivent être complétés !";
      }
    },
  },
};
</script>

<style scoped></style>